import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "./withRouter"; // Importamos una función para usar parámetros

import { API_PATH } from "./config.js";

class NoticiaId extends Component {
    constructor(props) {
        super(props);
        this.state = {
        noticia: null,
        loading: true,
        };
    }

    componentDidMount() {
        const { id } = this.props.params; // ID desde la URL
        this.fetchNoticia(id);
    }

    fetchNoticia = (id) => {
        axios.get(`${API_PATH}news/${id}`)
        .then(response => {
            this.setState({ noticia: response.data, loading: false });
        })
        .catch(error => {
            console.error("Error fetching noticia:", error);
            this.setState({ loading: false });
        });
    };

  render() {
        const { noticia, loading } = this.state;

        if (loading) return <p>Cargando noticia...</p>;
        if (!noticia) return <p>No se encontró la noticia.</p>;
        const video = noticia.acf.archivo;
        var videoLink 

        if(video === false){
            videoLink=""
        }else{
            videoLink= <embed src={video} width='100%' height='600px'/>
        }

    return (
        <>
        <div className="container-fluid bg-black" >
            <br/><br/>
            <h1 align="center" className="tituloPrincipal"><b>Noticias</b></h1>
        </div>
        <br/><br/>
        <div className="container">
            <div className='row'>
                    <h1 className="content-news-h1" dangerouslySetInnerHTML={{ __html: noticia.title.rendered }}></h1>
                    <br /><br />
                    <div>
                    <br />
                    <span className="pjus"  dangerouslySetInnerHTML={{ __html: noticia.content.rendered }}></span>
                    <br/><br/>
                    {videoLink}
                    </div>
            </div>
            <br/> <br/>
        </div>
        </>
    );
  }
}

export default withRouter(NoticiaId);
