import React, { Component } from 'react';
import { BrowserRouter as Router,  Routes, Route} from "react-router-dom";
import './Initial/config.js';
import NavBar from './Menu.js';
import Footer from './Footer.js';
import Noticias from './Initial/noticias.js';
import NoticiaId from './Initial/NoticiasID.js';
import Retorno from './Initial/Retorno.js';
import RetornoId from './Initial/RetornoID.js';
import Error404 from './Initial/Error404.js';
import Home from './Initial/Home.js';
import Organigrama from './Initial/Organigrama.js';
import LosQueLlegan from './Initial/LosQueLlegan.js';
import LosQueCrecen from './Initial/LosQueCrecen.js';
import LosQueCumplen from './Initial/LosQueCumplen.js';
import Aniversarios from './Initial/Aniversarios.js';
import AlgoParaCelebrar from './Initial/AlgoParaCelebrar.js';
import Conoce from './Initial/Conoce.js';
import WorkApps from './Initial/WorkApps.js';
import PoliticasFormatos from './Initial/PoliticasFormatos.js';
import Finanzas from './Initial/Finanzas.js';
import Bienestar from './Initial/Bienestar.js';
import TuSalud from './Initial/TuSalud.js';
import OtrasCosasBuenas from './Initial/OtrasCosasBuenas.js';
import Vacantes from './Initial/Vacantes.js';
import ElCorcho from './Initial/ElCorcho.js';
import SalaDeLectura from './Initial/SalaDeLectura.js';
import Comprobaciones from './Initial/Comprobaciones.js';

class App extends Component {
  render() {
    return (
      <Router>
        <NavBar/>
        
            <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/noticias" element={<Noticias/>} />
              <Route exact path="/noticias/:id" element={<NoticiaId/>} />
              <Route exact path="/b2n" element={<Retorno/>} />
              <Route exact path="/b2n/:id" element={<RetornoId/>} />
              <Route exact path="/organigrama" element={<Organigrama /> } />
              <Route exact path="/losquellegan" element={<LosQueLlegan/>} />
              <Route exact path="/losquecrecen" element={<LosQueCrecen/>} />
              <Route exact path="/losquecumplen" element={<LosQueCumplen/>} />
              <Route exact path="/aniversarios" element={<Aniversarios/>} />
              <Route exact path="/algoparacelebrar" element={<AlgoParaCelebrar/>} />
              <Route exact path="/conocemejora" element={<Conoce/>} />
              <Route exact path="/workapps" element={<WorkApps/>} />
              <Route exact path="/politicas-y-formatos" element={<PoliticasFormatos/>} />
              <Route exact path="/finanzas" element={<Finanzas/>} />
              <Route exact path="/cultura-bienestar" element={<Bienestar/>} />
              <Route exact path="/tu-salud" element={<TuSalud/>} />
              <Route exact path="/otras-cosas-buenas" element={<OtrasCosasBuenas/>} />
              <Route exact path="/vacantes" element={<Vacantes/>} />
              <Route exact path="/el-corcho" element={<ElCorcho/>} />
              <Route exact path="/sala-de-lectura" element={<SalaDeLectura/>} />
              <Route exact path="/comprobaciones" element={<Comprobaciones/>} />


              <Route path="*"  element={<Error404/>} />
            </Routes>
            <Footer/>

      </Router>
    )
  }
}


export default (App)


