import React from "react";
import { AuthenticatedTemplate} from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import Menu from './Routes.js'
// MSAL imports
import { MsalProvider } from "@azure/msal-react";
/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {    
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Menu/>
            </AuthenticatedTemplate>
        </div>
    );
};

function App({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app

    return (
        <MsalProvider instance={pca}>
            <PageLayout>
            <MainContent />
            </PageLayout>
        </MsalProvider>
    );
}

export default App;
